var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    staticStyle: {
      "width": "100%",
      "height": "65vh",
      "border": "0"
    }
  }, [_c('h2', {
    staticClass: "mb-4"
  }, [_vm._v("Health, Dental, Vision & Life")]), _c('b-tabs', {
    staticStyle: {
      "width": "100%",
      "border": "0"
    },
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "title": "General Information",
      "active": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": "input:renewal_date",
      "value-single": _vm.data['Renewal Date'],
      "label": "Plan Renewal Date",
      "readonly": !_vm.$permitted('input:renewal_date').write,
      "mode": _vm.$constants.FORM_MODE.VIEW
    },
    on: {
      "changed": _vm.updateRenewalDate
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Documents: ")])], 1), _c('hr'), _c('iframe', {
    staticStyle: {
      "width": "100%",
      "height": "100%",
      "border": "0"
    },
    attrs: {
      "src": "https://drive.google.com/embeddedfolderview?id=1ZbrWsin77t2egiI_HfHPbV5L77Ty4sKH"
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Eligibility"
    }
  }, [_c('ul', {
    staticClass: "w-50"
  }, [_c('li', [_vm._v("Full-time position")]), _c('li', [_vm._v(" You will become eligible the first month after completion of the mandatory introductory period (3 months) ")])])]), _c('b-tab', {
    attrs: {
      "title": "Internal Contact"
    }
  }, [_c('ul', {
    staticClass: "w-50"
  }, [_c('li', [_vm._v(" Oleg Raskin (oleg.raskin@kovaldistillery.com) ")])])]), _c('b-tab', {
    attrs: {
      "title": "Provider Information"
    }
  }, [_c('ul', {
    staticClass: "w-50"
  }, [_c('li', [_vm._v("TASK IG (broker)")]), _c('li', [_vm._v("Andrea Quindel: AQuindel@taskig.com")])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }