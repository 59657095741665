<template>
  <div class="animated fadeIn">
    <b-card style="width:100%; height:65vh; border:0;">
      <h2 class="mb-4">Health, Dental, Vision & Life</h2>
      <b-tabs content-class="mt-3" justified style="width:100%; border:0;">
        <b-tab title="General Information" style="height:100%" active>
          <b-row>
            <b-col cols="2">
              <inline-date-picker
                id="input:renewal_date"
                :value-single="data['Renewal Date']"
                label="Plan Renewal Date"
                :readonly="!$permitted('input:renewal_date').write"
                :mode="$constants.FORM_MODE.VIEW"
                @changed="updateRenewalDate"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="2">
              Documents:
            </b-col>
          </b-row>
          <hr />
          <iframe
            src="https://drive.google.com/embeddedfolderview?id=1ZbrWsin77t2egiI_HfHPbV5L77Ty4sKH"
            style="width:100%; height:100%; border:0;"
          ></iframe>
        </b-tab>
        <b-tab title="Eligibility">
          <ul class="w-50">
            <li>Full-time position</li>
            <li>
              You will become eligible the first month after completion of the
              mandatory introductory period (3 months)
            </li>
          </ul>
        </b-tab>
        <b-tab title="Internal Contact">
          <ul class="w-50">
            <li>
              Oleg Raskin (oleg.raskin@kovaldistillery.com)
            </li>
          </ul>
        </b-tab>
        <b-tab title="Provider Information">
          <ul class="w-50">
            <li>TASK IG (broker)</li>
            <li>Andrea Quindel: AQuindel@taskig.com</li>
          </ul>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      data: {
        "Renewal Date": ""
      }
    };
  },
  created() {},
  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.data = await this.$api.get("benefit/insurance");
    },
    async updateRenewalDate(e) {
      this.data["Renewal Date"] = e.valueSingleAsString;

      await this.$api.put("benefit/insurance", this.data);
    }
  }
};
</script>

<style scoped>
::v-deep .tab-content {
  height: 90%;
}

::v-deep .tabs {
  height: 100%;
}
</style>
